/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import { INLINES } from '@contentful/rich-text-types';
import { AboutProps, AboutPageProp } from '@/types';
import AboutStyled from '@/components/styled/AboutStyled';
import ContentfulRawToComponent from '@/components/utils/ContentfulRawToComponent';
import People from '@/app/people/people';
import { AboutImage } from '@/components/fragments/AboutImage';
import { usePageMeta } from '@/components/app/hooks';
import Reveal from '@/components/reveal/Reveal';

const override = {
  renderNode: {
    [INLINES.HYPERLINK]: ({ data }, children) => (
      <a
        href={data.uri}
        target="_blank"
        rel="noopener noreferrer"
        className="link alt"
      >
        {children}
      </a>
    ),
  },
};

const About: React.FC<AboutPageProp> = ({ data }) => {
  const [section, setSection] = useState(null);

  let headingMeta = {
    meta: {},
  };

  if (data.allContentfulBlock.nodes.filter((e) => e.slug === `about-heading`)) {
    const aboutHeader = data.allContentfulBlock.nodes.filter(
      (e) => e.slug === `about-heading`,
    );

    headingMeta = { meta: aboutHeader[0]?.meta };
  }

  useEffect(() => {
    const items = {};
    data.allContentfulBlock.nodes.forEach((_item: AboutProps) => {
      items[_item.slug.replace(`-`, `_`)] = _item;
    });

    // filter contentful blocks for predefined image dimension queries
    const filteredBlocks = Object.entries(data).filter(
      ([key, _value]) =>
        key === `aboutPartners` ||
        key === `aboutHeading` ||
        key === `aboutPeople`,
    );

    // replace contentful block about-heading & about-partners generic image with predefined image dimensions
    filteredBlocks.forEach(([, value]) => {
      value.nodes.forEach((_item) => {
        const slug = _item.slug.replace(`-`, `_`);
        items[slug].image = _item.image;
      });
    });

    setSection(items);
  }, []);

  return (
    <AboutStyled>
      {usePageMeta(headingMeta?.meta)}
      <div className="content about-page">
        <Reveal className="page-title h1-title container">
          <h1>About</h1>
        </Reveal>
        {section?.about_heading && (
          <div className="page-header">
            <Reveal className="heading container">
              <ContentfulRawToComponent
                raw={section.about_heading.content.raw}
              />
            </Reveal>
            <Reveal className="header-image container">
              <AboutImage
                image={section.about_heading?.image}
                alt={section.about_heading.title || ``}
              />
            </Reveal>
          </div>
        )}

        <div className="page-content">
          {section?.about_heading && (
            <div className="about-company container">
              <div className="two-column-image-text left">
                <Reveal className="img-container">
                  <AboutImage
                    image={section?.about_heading?.image2}
                    alt={section.about_heading.title || ``}
                  />
                </Reveal>
                <Reveal className="text-container">
                  <ContentfulRawToComponent
                    raw={section.about_heading.content2.raw}
                  />
                </Reveal>
              </div>

              <div className="two-column-image-text right">
                <Reveal className="img-container">
                  <AboutImage
                    image={section.about_heading?.image3}
                    alt={section.about_heading.title || ``}
                  />
                </Reveal>

                <Reveal className="text-container">
                  <ContentfulRawToComponent
                    raw={section.about_heading.content3.raw}
                  />
                </Reveal>
              </div>
            </div>
          )}

          {!!section?.about_partners && (
            <div className="our-partners">
              <div className="partners-container container">
                <div className="image-section">
                  <div className="image-container">
                    {section?.about_partners && (
                      <AboutImage
                        image={section.about_partners?.image}
                        alt={section.about_partners.title || ``}
                      />
                    )}
                  </div>
                </div>

                <div className="partners-details">
                  <Reveal className="partners-header" element="h2">
                    {section?.about_partners && section.about_partners.title}
                  </Reveal>
                  <div className="partners-list">
                    {!!section?.about_partners?.content && (
                      <Reveal className="partner-category">
                        <ContentfulRawToComponent
                          options={override}
                          raw={section.about_partners.content.raw}
                        />
                      </Reveal>
                    )}
                    {!!section?.about_partners?.content2 && (
                      <Reveal className="partner-category">
                        <ContentfulRawToComponent
                          options={override}
                          raw={section.about_partners.content2.raw}
                        />
                      </Reveal>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}

          {section?.about_people && (
            <People
              people={section.about_people}
              staff={data.allContentfulStaff.nodes}
            />
          )}
        </div>
      </div>
    </AboutStyled>
  );
};

export const query = graphql`
  query AboutQuery {
    aboutPartners: allContentfulBlock(
      filter: { slug: { eq: "about-partners" } }
    ) {
      nodes {
        slug
        image {
          ...aboutSectionImageDataFragment
        }
      }
    }

    aboutHeading: allContentfulBlock(
      filter: { slug: { eq: "about-heading" } }
    ) {
      nodes {
        slug
        image {
          ...aboutHeaderImageDataFragment
        }
      }
    }

    aboutPeople: allContentfulBlock(filter: { slug: { eq: "about-people" } }) {
      nodes {
        slug
        image {
          ...peopleHeaderImageDataFragment
        }
      }
    }

    allContentfulStaff(sort: { fields: order }) {
      nodes {
        countryCode
        linkedInProfile
        designation
        photo {
          gatsbyImageData(
            width: 488
            outputPixelDensities: [0.2, 0.25, 0.3, 0.35, 0.4]
          )
          description
        }
        id
        firstName
        lastName
        phoneNumber
        emailAddress
        isActive
      }
    }
    allContentfulBlock(
      filter: { pages: { eq: "about" } }
      sort: { fields: order }
    ) {
      nodes {
        slug
        pages
        order
        title
        text {
          text
        }
        text2 {
          text2
        }
        image {
          gatsbyImageData
        }
        image2 {
          ...aboutSectionImageDataFragment
        }
        image3 {
          ...aboutSectionImageDataFragment
        }
        content {
          raw
        }
        content2 {
          raw
        }
        content3 {
          raw
        }
        meta {
          title
          description
          type
          image {
            file {
              url
            }
          }
        }
      }
    }
  }
`;
export default About;
