/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { graphql } from 'gatsby';
import { IpeopleProps, StaffProps } from '@/types';
import StaffItem from './StaffItem';
import { PeopleImage } from '@/components/fragments/PeopleImage';
import Reveal from '@/components/reveal/Reveal';

const People: React.FC<IpeopleProps> = ({ people, staff }) => (
  <div className="our-people container">
    <div className="section-header">
      <Reveal element="h2">Our people</Reveal>
      <Reveal className="header-image">
        <PeopleImage image={people.image} alt={people.title} />
      </Reveal>
    </div>

    <div className="section-content">
      <div className="staff-list" id="staff-list">
        <Reveal className="staff list-main-image">
          <PeopleImage image={people.image} alt={people.title} />
        </Reveal>

        {staff.map(
          (_staff: StaffProps) =>
            _staff.firstName &&
            _staff.isActive && <StaffItem staff={_staff} key={_staff.id} />,
        )}
      </div>
    </div>
  </div>
);

export default People;

export const query = graphql`
  fragment peopleHeaderImageDataFragment on ContentfulAsset {
    wide: gatsbyImageData(
      width: 1504
      outputPixelDensities: [0.2, 0.25, 0.3, 0.35, 0.4]
    )
    large: gatsbyImageData(
      width: 1136
      outputPixelDensities: [0.2, 0.25, 0.3, 0.35, 0.4]
    )
    desktop: gatsbyImageData(
      width: 960
      outputPixelDensities: [0.2, 0.25, 0.3, 0.35, 0.4]
    )
    tablet: gatsbyImageData(
      width: 1216
      outputPixelDensities: [0.2, 0.25, 0.3, 0.35, 0.4]
    )
    mobile: gatsbyImageData(
      width: 754
      outputPixelDensities: [0.2, 0.25, 0.3, 0.35, 0.4]
    )
  }
`;
